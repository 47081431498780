/**
 * Animation utilities to help migrate from WOW.js to AOS
 * This file provides helper functions to convert legacy WOW.js animations
 * to modern AOS (Animate on Scroll) data attributes
 */

// Dictionary to map WOW animation classes to AOS attributes
const wowToAosMap = {
  // Fade animations
  'fadeIn': 'fade-in', 
  'fadeInUp': 'fade-up',
  'fadeInDown': 'fade-down',
  'fadeInLeft': 'fade-right', // Note: AOS directions are inverted
  'fadeInRight': 'fade-left', // Note: AOS directions are inverted
  
  // Zoom animations
  'zoomIn': 'zoom-in',
  'zoomInUp': 'zoom-in-up',
  'zoomInDown': 'zoom-in-down',
  'zoomInLeft': 'zoom-in-right', // Note: AOS directions are inverted
  'zoomInRight': 'zoom-in-left', // Note: AOS directions are inverted
};

/**
 * Converts existing WOW.js elements to use AOS
 * This can be run on DOM ready to automatically convert legacy animations
 */
export const convertWowToAos = () => {
  // Find all elements with the 'wow' class
  const wowElements = document.querySelectorAll('.wow');
  
  wowElements.forEach(element => {
    // Get all classes from the element
    const classes = element.className.split(' ');
    
    // Find animation class (like fadeInLeft, zoomIn, etc.)
    let animationClass = null;
    for (const cls of classes) {
      // Skip the 'wow' class itself
      if (cls === 'wow') continue;
      
      // Check if this class is one of our mapped animation classes
      if (Object.keys(wowToAosMap).includes(cls)) {
        animationClass = cls;
        break;
      }
    }
    
    if (animationClass) {
      // Remove WOW classes
      element.classList.remove('wow');
      element.classList.remove(animationClass);
      
      // Add AOS attributes
      element.setAttribute('data-aos', wowToAosMap[animationClass]);
      
      // Optional: You can add more AOS attributes as needed
      // element.setAttribute('data-aos-duration', '1000');
      // element.setAttribute('data-aos-delay', '300');
    }
  });
  
};

export default { convertWowToAos }; 